import { render, staticRenderFns } from "./ProfileOrgEditJournalNoteView.vue?vue&type=template&id=c69a35dc&scoped=true&"
import script from "./ProfileOrgEditJournalNoteView.vue?vue&type=script&lang=js&"
export * from "./ProfileOrgEditJournalNoteView.vue?vue&type=script&lang=js&"
import style0 from "./ProfileOrgEditJournalNoteView.vue?vue&type=style&index=0&id=c69a35dc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c69a35dc",
  null
  
)

export default component.exports